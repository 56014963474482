angular.module("common")
    .filter('properties', unitFilter);

unitFilter.$inject = [];

function unitFilter() {
    return function(entries, properties, value) {

        if (!value) return entries;

        var propertiesList = properties.split('|');

        return entries.filter(function(item){
           for(var key in propertiesList) {
               if(item[propertiesList[key]].toLowerCase().indexOf(value.toLowerCase()) >= 0) {
                   return true;
               }
           }

           return false;
        });
    }
}